import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const DeviceVariables = {
  AuthToken:
    'eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiemlwIjoiREVGIn0.P5hf_Rys9iyln86U95ziYTJxUS1dUB6y2MJvkldOHWCKHNVngGOmUabTF_P24c2xrCfUM3CNHvJzjUAdoGt3QISsqMEFOHpU.6tg-6Skmd7tNgkKmNGDwKQ.KsscmukUFXp6Q48FmLe5SWI9MG4aYZo1mvEJu2HaIIWyfpMGtO8nWixbfcc-a0TfyVies9P9XmD5ei6q0V8H34ZmLvFWDsGNg8iFQxftF6LuDpSfl3ttFctJDBB1pLQ6K6FBK3Ex1ZJUsCsk5lZang.TDgWpqh1Y3AZB8y6BVkajnBClIM0TZWGT9rZJZt_XQs',
  PRIMARY_ADDRESS: {},
  STRIPE_PUBLISHABLE_KEY:
    'pk_test_51NuFvfKZLTSiovpVdvsF4PeQSiXINYUISRnUpmAmCgaQYDB3lNdK9zWnux3CCJ1SgqoKYLOey7zCRUVtntaeFiqE00LwyZJR1s',
  USER_DATA: {},
  __env__: 'Development',
};
export const AppVariables = {
  CARD_TO_SHOW_MENU: 0,
  DATASOURCE: 'test',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDUbzdueDcHvwjmDmyqd9l2f5agQiW0JWw',
  SAMPLE_CSV_URL:
    'https://xhrm-5alx-lhb1.n7.xano.io/vault/cH9onT_o/09KDwIgmCFFFC38Mb7dRwqiDBSw/kHzgvQ../sample_contacts.csv',
  SHOW_DRAWER: false,
  silder_data: [
    {
      texts: [
        {
          content:
            'Experience a new way to receive personalized content from businesses you love. Our innovative platform ensures that you get the most relevant offers, updates, and information tailored just for you.',
          subtitle: 'Welcome to Mailboxx!',
        },
        {
          content:
            'Receive messages based on your location, age, gender, and interests.',
          subtitle: 'Personalized Content',
        },
        {
          content: 'Never miss out on exciting offers and important updates.',
          subtitle: 'Stay Informed',
        },
      ],
      title: 'Welcome to Mailboxx!',
    },
    {
      texts: [
        {
          content:
            'At Mailboxx, we respect your privacy and strive to give you full control over the content you receive. No more unwanted messages cluttering your inbox.',
          subtitle: 'Your Privacy Matters!',
        },
        {
          content:
            'Say goodbye to physical mail spam, text spam, telemarketing calls, and email spam.',
          subtitle: 'Consolidate Spam',
        },
        {
          content:
            'We’ve created a platform which will consolidate spam and force businesses to contact users through the Mailboxx channel.',
          subtitle: 'Embrace Spam',
        },
      ],
      title: 'Your Privacy, Our Priority',
    },
    {
      texts: [
        {
          content:
            'By joining Mailboxx, you become part of a community that values meaningful interactions and relevant content. Together, we can transform the way businesses connect with their customers.',
          subtitle: 'Join the Future of Communication!',
        },
        {
          content:
            'Enjoy a clutter-free, organized inbox with content that truly matters.',
          subtitle: 'Enhanced User Experience',
        },
        {
          content:
            'Engage with brands and businesses that understand your needs and preferences.',
          subtitle: 'Support Businesses You Love',
        },
      ],
      title: 'Join the Mailboxx Community',
    },
  ],
};
const GlobalVariableContext = React.createContext();
const GlobalVariableUpdater = React.createContext();
const keySuffix = '';

// Attempt to parse a string as JSON. If the parse fails, return the string as-is.
// This is necessary to account for variables which are already present in local
// storage, but were not stored in JSON syntax (e.g. 'hello' instead of '"hello"').
function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}

class GlobalVariable {
  /**
   *  Filters an object of key-value pairs for those that should be
   *  persisted to storage, and persists them.
   *
   *  @param values Record<string, string>
   */
  static async syncToLocalStorage(values) {
    const update = Object.entries(values)
      .filter(([key]) => key in DeviceVariables)
      .map(([key, value]) => [key + keySuffix, JSON.stringify(value)]);

    if (update.length > 0) {
      await AsyncStorage.multiSet(update);
    }

    return update;
  }

  static async loadLocalStorage() {
    const keys = Object.keys(DeviceVariables);
    const entries = await AsyncStorage.multiGet(
      keySuffix ? keys.map(k => k + keySuffix) : keys
    );

    // If values isn't set, use the default. These will be written back to
    // storage on the next render.
    const withDefaults = entries.map(([key_, value]) => {
      // Keys only have the suffix appended in storage; strip the key
      // after they are retrieved
      const key = keySuffix ? key_.replace(keySuffix, '') : key_;
      return [key, value ? tryParseJson(value) : DeviceVariables[key]];
    });

    return Object.fromEntries(withDefaults);
  }
}

class State {
  static defaultValues = {
    ...AppVariables,
    ...DeviceVariables,
  };

  static reducer(state, { type, payload }) {
    switch (type) {
      case 'RESET':
        return { values: State.defaultValues, __loaded: true };
      case 'LOAD_FROM_ASYNC_STORAGE':
        return { values: { ...state.values, ...payload }, __loaded: true };
      case 'UPDATE':
        return state.__loaded
          ? {
              ...state,
              values: {
                ...state.values,
                [payload.key]: payload.value,
              },
            }
          : state;
      default:
        return state;
    }
  }

  static initialState = {
    __loaded: false,
    values: State.defaultValues,
  };
}

export function GlobalVariableProvider({ children }) {
  const [state, dispatch] = React.useReducer(State.reducer, State.initialState);

  React.useEffect(() => {
    async function prepare() {
      await SplashScreen.preventAutoHideAsync();
    }

    prepare();
  }, []);

  // This effect runs on mount to overwrite the default value of any
  // key that has a local value.
  React.useEffect(() => {
    async function initialStorageLoader() {
      try {
        const payload = await GlobalVariable.loadLocalStorage();
        if (
          payload?.__env__ &&
          DeviceVariables.__env__ &&
          payload.__env__ !== DeviceVariables.__env__
        ) {
          console.log(
            `Publication Environment changed from ${payload.__env__} to ${DeviceVariables.__env__}. Refreshing variables`
          );
          dispatch({
            type: 'LOAD_FROM_ASYNC_STORAGE',
            payload: DeviceVariables,
          });
        } else {
          dispatch({ type: 'LOAD_FROM_ASYNC_STORAGE', payload });
        }
      } catch (err) {
        console.error(err);
      }
    }
    initialStorageLoader();
  }, []);

  // This effect runs on every state update after the initial load. Gives us
  // best of both worlds: React state updates sync, but current state made
  // durable next async tick.
  React.useEffect(() => {
    async function syncToAsyncStorage() {
      try {
        await GlobalVariable.syncToLocalStorage(state.values);
      } catch (err) {
        console.error(err);
      }
    }
    if (state.__loaded) {
      syncToAsyncStorage();
    }
  }, [state]);

  const onLayoutRootView = React.useCallback(async () => {
    if (state.__loaded) {
      await SplashScreen.hideAsync();
    }
  }, [state.__loaded]);

  // We won't want an app to read a default state when there might be one
  // incoming from storage.
  if (!state.__loaded) {
    return null;
  }

  return (
    <GlobalVariableUpdater.Provider
      value={dispatch}
      onLayout={onLayoutRootView}
    >
      <GlobalVariableContext.Provider value={state.values}>
        {children}
      </GlobalVariableContext.Provider>
    </GlobalVariableUpdater.Provider>
  );
}

// Hooks
export function useSetValue() {
  const dispatch = React.useContext(GlobalVariableUpdater);
  return ({ key, value }) => {
    dispatch({ type: 'UPDATE', payload: { key, value } });
    return value;
  };
}

export function useValues() {
  return React.useContext(GlobalVariableContext);
}
